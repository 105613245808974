import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'components/SEO'
import Footer from './Footer'

import styles from './Legal.module.scss'

export const query = graphql`
  query DMCAPageQuery {

    banner: file(
      relativeDirectory: {regex: "/seo-banner/"},
      name: {eq: "home"}
    ) {
      publicURL
    }

    site {
      siteMetadata {
        siteUrl
      }
    }

  }
`

const dmcaPage = ({ data }) => {
  const { banner, site: { siteMetadata } } = data

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    'itemListElement': [
      {
        "@type": "ListItem",
        "position": 1,
        "item": {
          "@id": `${siteMetadata.siteUrl}/legal/`,
          "name": "Legal"
        }
      },
      {
        "@type": "ListItem",
        "position": 2,
        "item": {
          "@id": `${siteMetadata.siteUrl}/legal/dmca/`,
          "name": "DMCA"
        }
      },
    ]
  }

  return (
    <>
    <SEO
      breadcrumb={breadcrumb}
      title={`Report a problem or DMCA violation | SloppyDeep`}
      description={`Report here any DMCA violation or problem on sloppydeep.com, we will try to get back to you as soon as possible`}
      keywords={`dmca sloppydeep, dmca report sloppydeep, sloppydeep dmca, dmca policy sloppydeep`}
      imagePath={banner.publicURL}
      imageAlt={'redhead teen cam girl wearing pink lingerie and posing sexy'}
      pagePath={`/legal/dmca/`}
      robots='index, follow'
    />
    <div className={`${styles.legalScreen} screen-container`}>
      <div className={styles.documentTitle}>
        <h1>DMCA</h1>
        <span>updated on 13 march 2020</span>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>Report Content</h2>
        <div className={styles.sectionContent}>
          We do not own, host, produce or upload any videos displayed on this website, we only link to them.<br/>
          If you find inappropriate content that you believe should be removed (illegal content, copyright 
          infringement) you can contact the site owner where it is hosted.<br/><br/>

          By your request we can remove thumbnails or images from our website, you can inform us 
          at <a href='mailto:report@sloppydeep.com'>report@sloppydeep.com</a> and we will get back to you.<br/>
          We do our best to delete broken links, inappropriate content or copyrighted material when it is reported.
        </div>
      </div>
      <div className={styles.documentSection}>
        <h2 className={styles.sectionTitle}>2257</h2>
        <div className={styles.sectionContent}>
          All content and images are in full compliance with the requirements of 18 U.S.C. 2257 and associated 
          regulations.<br/><br/>
          
          If you have any further question please email us at <a href='mailto:contact@sloppydeep.com'>contact@sloppydeep.com</a>
        </div>
      </div>
      <Footer />
    </div>
    </>
  )
}

export default dmcaPage
